.case-study-container {
  max-width: 1000px;
  margin: 0 auto 0 auto;
}

.case-study-container >:last-child {
  margin-bottom: 0;
}

.cover-image {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 32px;
}

.case-study-container p {
  font-size: 1.5rem;
}

.case-study-container .tech-tag {
  padding: 1rem 2rem;
  font-size: 1.25rem;
}
.case-study-container .tech-tags {
  margin: 32px 0 50px 0;
}

.case-study-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  margin-bottom: 72px;
}

.why-container {
  border-radius: 16px;
  display: flex;
  justify-content: center;
  position: relative;
}

#why-text {
  max-width: 600px;
  padding: 40px 32px;
}

.swatch-group-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

.color-font-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}

.typeface {
  font-size: 2rem !important;
}

.video-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-80 {
  width: 80%;
  display: block;
  margin: 48px auto 0 auto;
}



/* ------ UXJOBSBOARD STUDY */

#uxjobsboard-arrow {
  width: 400px;
  margin: 0 0 0 24px;
}

.user-quote {
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 12px 32px 12px 16px;
  background-color: white;
}

.user-quote img {
  width: 50px;
}

.wireframe-container-main {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: flex-start;
}
.wireframe-container-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#prototype {
  width: 300px;
}

#task {
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: #8D53D9;
  border-radius: 16px;
  font-size: 1rem;
  padding: 24px;
}

#task svg {
  min-width: 40px;
  max-width: 40px;
}

#laptop {
  position: absolute;
  top: 60px;
  right: 5%;
}
#lightbulb {
  position: absolute;
  top: 60px;
  right: 2%;
  width:  180px;
}
#illustrations {
  z-index: 999;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
}

#uxjobsboard-gif {
  max-width: 60%;
  margin-top: 60px;
  margin-bottom: 120px;
}

/* ------ MOVIE CASE STUDY */

#cafe-arrow {
  width: 250px;
  margin: 8px 0 0 24px;
}

/* ------ MOVIE CASE STUDY */
#popcorn {
  position: absolute;
  bottom: -80px;
  right: 100px;
  rotate: 10deg;
}

#ipad {
  width: 83%;
  position: absolute;
  top: 73px;
  left: 8%;
}


/* -----------RESPONSIVENESS------------------ */

@media screen and (max-width: 1000px) {
  #ipad {
    top: 80px;
  }
  #lightbulb {
    top: 90%;
  }
}
@media screen and (max-width: 900px) {
  .wireframe-container-main {
    flex-direction: column;
    align-items: center;
  }
  #laptop {
    top: 80%;
    rotate: 50deg;
  }
}

@media screen and (max-width: 750px) {
  .color-font-info {
    grid-template-columns: 1fr;
  }
  #ipad {
    top: 90px;
  }
  .img-80 {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .case-study-container p {
    font-size: 1.25rem;
  }
  .font-1-25rem {
    font-size: 1rem !important;
  }
  .case-study-container .tech-tag {
    font-size: 1rem;
  }
  #popcorn {
    width: 130px;
    bottom: -100px;
  }
}
@media screen and (max-width: 550px) {
  .case-study-container .tech-tag {
    padding: 12px 24px;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 500px) {
  .case-study-container p {
    font-size: 1rem;
  }
  .font-1-25rem {
    font-size: 0.875rem !important;
  }
  #popcorn {
    right: 0;
  }
  #uxjobsboard-arrow {
    width: 100%;
    margin: 0;
  }
  #prototype {
    width: 200px;
  }
}
@media screen and (max-width: 400px) {
  #ipad {
    top: 96px;
  }
  .case-study-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    margin-bottom: 72px;
  }
  .typeface {
    font-size: 1.5rem !important;
  }
  #cafe-arrow {
    width: 180px;
    margin: 8px 0 0 24px;
  }
}
@media screen and (max-width: 350px) {
  #ipad {
    top: 96px;
  }
  .case-study-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    margin-bottom: 72px;
  }
}
