.skills-container {
  column-count: 3;
}

#profile-image {
  max-width: 150px;
  max-height: 150px;
  border-radius: 16px;
  margin-bottom: 40px;
  margin-left: 20px;
}
.about-section {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 100px;
}
.about-inner {
  display: flex;
}
#scroll-about:before {
  height: 40px;
  content: "";
  display: block;
}
@media screen  and (max-width: 1100px ) {
  .about-section {
    gap: 40px;
  }
}
@media screen and (max-width: 900px) {
  .skills-container {
    column-count: 2;
  }
}
@media screen and (max-width: 750px) {
  .about-section {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .about-inner {
    display: inline;
  }
  #profile-image {
    margin-top: 20px;
    margin-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .skills-container {
      column-count: 1;
    }
}
