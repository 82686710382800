.intro-container {
  display: flex;
  flex-direction: column;
}

.intro-top {
  margin: 30px 0 30px 0;
  line-height: 1.4em;
}

.intro-bottom {
  display: flex;
  justify-content: flex-end;
  gap: 120px;
  font-size: 1.5rem;
}

.arrow-email {
  display: flex;
}

.email-top-margin {
  margin-top: 33px;
}

@media screen and (max-width: 750px) {
  .intro-top {
    line-height: 1.4em;
    font-size: 1.4rem;
  }

  .intro-bottom {
    gap: 56px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .intro-bottom {
    flex-direction: column;
    gap: 32px;
    font-size: 1rem;
  }
  .intro-top {
    margin: 10px 0 10px 0;
  }
  .arrow-email {
    flex-direction: column;
  }
  .arrow {
    transform: rotate(120deg);
    height: 50px;
  }
}
