#scroll-contact {
  padding-bottom: 80px;
}

#scroll-contact a {
  text-decoration: none;
  font-weight: 500;
  color: var(--black);
  font-size: 1.5rem;
}

.contact-container.text-white a {
color: white !important;
}

.extra-paragraph {
  font-size: 1.5rem;
}

#scroll-contact a:hover {
  text-decoration: underline;
}

.contact-header {
  text-transform: none;
}

#scroll-contact:before {
  height: 40px;
  content: "";
  display: block;
}


/* MIN-WIDTH */
@media screen and (min-width: 551px) {
  .contact-header {
      font-size: 60px;
  }
}
@media screen and (max-width: 450px) {
  .extra-paragraph {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 310px) {
  .email, .extra-paragraph {
    font-size: 1rem;
  }
}
