.project-container {
  margin-bottom: 140px;
  max-width: 800px;
}

.project-container:nth-child(odd) {
  margin-left: auto;
  margin-right: 0;
}

.main-container .project-container:last-child {
  margin-bottom: 0;
}

.project-info {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.project-info a {
  margin: 32px 0;
}

/* IMAGE */

.image {
  width: 100%;
  height: 0;
  padding-top: 63%;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
}

.image-container {
  width: 100%;
}

.image-container:hover {
  cursor: pointer;
  filter: brightness(90%);
}

/* TAGS */

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tech-tag {
  color: var(--black);
  padding: 24px 40px;
  border-radius: 50px;
  border: 1px solid var(--black);
  font-weight: 500;
  font-size: 1.25rem;
}

/* RESPONSIVENESS */


@media screen and (max-width: 1400px) {
  .project-container {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1200px) {
  .project-container:nth-child(even) {
    margin-left: 0;
    margin-right: auto;
  }
  .project-container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1000px) {
  .project-info a {
    margin: 24px 0;
  }
}

@media screen and (max-width: 750px) {
  .project-container {
    margin-bottom: 88px;
  }

  .project-info a {
    margin: 20px 0;
  }

  .tech-tag {
    padding: 16px 32px;
    border-radius: 50px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .project-info a {
    margin: 16px 0;
  }
  .tech-tag {
    padding: 12px 24px;
    border-radius: 50px;
    font-size: 0.875rem;
  }
}
