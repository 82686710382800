.animation-image {
  width: 100%;
}

.animation-mask {
  height: 700px;
  overflow: hidden;
  display: flex;
  gap: 16px;
  border-radius: 16px;
  width: fit-content;
  --animation-offset: 7700px;
  --animation-duration: 45s;
  margin-bottom: 60px;
}

.animation-mask > * {
  flex: 1 1 0;
}

@keyframes changeYup {
  100% {
    transform: translateY(calc(var(--animation-offset)*-1));
  }
}

@keyframes changeYdown {
  100% {
    transform: translateY(var(--animation-offset));
  }
}

.animation-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  animation-name: changeYup;
  animation-duration: var(--animation-duration);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

.animation-column:nth-child(even) {
  margin-top: calc(var(--animation-offset)*-1);
  animation-name: changeYdown;
  animation-duration: var(--animation-duration);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

.link-button-mb {
  margin-bottom: 12px;
}

@media screen and (max-width: 1350px) {
  .animation-mask {
    --animation-offset: 5500px;
  }
}
@media screen and (max-width: 1000px) {
  .animation-mask {
    --animation-offset: 4000px;
  }
}
@media screen and (max-width: 750px) {
  .animation-mask {
    height: 500px;
    --animation-offset: 3000px;
  }
}
@media screen and (max-width: 550px) {
  .animation-mask {
    height: 400px;
    --animation-offset: 2000px;
    margin-bottom: 32px;
  }
  .link-button-mb {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 400px) {
  .animation-column {
      gap: 8px;
  }
  .animation-mask {
    --animation-offset: 1000px;
    height: 300px;
    gap: 8px;
  }
}
@media screen and (max-width: 250px) {
  .animation-mask {
    --animation-offset: 500px;
  }
}
@media screen and (max-width: 150px) {
  .animation-mask {
    --animation-offset: 300px;
  }
}
