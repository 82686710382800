.illustrations-container {
  margin: 0 auto 0 auto;
}

.illustration-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 25% auto 25% auto;
}
.illustration-container:first-child {
  margin-top: 0;
}
.illustration-container:last-child {
  margin-bottom: 0;
}


.illustration-container:nth-child(even) {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: end;
}
.illustration-container:nth-child(odd) {
  margin-left: 0;
  margin-right: auto;
}
.image-description {
  font-size: 0.875rem;
  margin-top: 1.5rem;
}
@media screen and (max-width: 600px) {
  .illustration {
    width: 100%;
  }
}
