.footer.main-container {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}

.footer.text-white a {
  color: white;
}

.social-links {
  display: flex;
}

.social-links a {
  text-decoration: none;
  font-weight: 500;
  color: var(--black);
  font-size: 1.25rem;
  margin-right: 48px;
  transition: 0.2s ease-in;
}

.social-links a:hover {
  text-decoration: underline;
  color: var(--black);
}

.copyright {
  font-size: 1.25rem;
}

@media screen and (max-width: 750px) {
  .social-links {
    font-size: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .footer, .social-links {
    flex-direction: column;
    gap: 16px;
  }
  .social-links {
    margin-bottom: 32px;
  }
  #scroll-contact {
    padding-bottom: 40px;
  }

}
