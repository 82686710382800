.navbar-logo {
  cursor: pointer;
  width: 80px;
}

.navbar-logo:hover {
  opacity: 0.7;
}

#name {
  font-family: 'Dela Gothic One', sans-serif;
  cursor: pointer;
}

header {
  position: sticky;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 10px 4%;
  /* position: sticky; */
  top: 0;
  z-index: 10000;
}

header nav {
  display: flex;
  align-items: center;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  display: none;
  font-size: 1.8rem;
}

nav a {
  margin: 0 1.5rem;
  color: var(--black);
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

nav a:nth-last-child(2) {
  margin-right: 0;
}

nav a:hover {
  color: var(--orange);
}

.nav-btn, .nav-close-btn {
  color: var(--black);
}

.nav-btn:hover, .nav-close-btn:hover {
  color: var(--orange);
}

/* CHECKBOX */

/* The switch - the box around the slider */
.lang-switch-container {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lang-switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}

/* Hide default HTML checkbox */
.lang-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.lang-switch-container p {
  font-size: 16px;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--orange);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #8e75f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 1000px) {
  header .nav-btn {
    display: block;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    transform: translateY(-100vh);
    -webkit-transform: translateY(-100vh);
    -ms-transform: translateY(-100vh);
    background-color: var(--bg);
    z-index: 1000;
  }

  nav a {
    font-size: 26px;
  }

  nav a:nth-last-child(2) {
    margin-right: 1.5rem;
  }

  header .responsive-nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 24px;
    right: 3%;
  }

  .lang-switch-container {
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 750px) {
  .navbar-logo {
    width: 60px;
  }
}
