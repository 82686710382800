.swatch-container {
  width: 140px;
  border: 1px solid grey;
  border-radius: 8px;
  background-color: white;
}

.color-container {
  border-bottom: 1px solid grey;;
  height: 100px;
  border-radius: 7px 7px 0 0;
}

.hex-container {
  padding: 8px;
}

.hex-container p {
  text-align: center;
}

@media screen and (max-width: 500px) {
  .swatch-container {
    max-width: 100px;
  }
}
