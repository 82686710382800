@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Londrina+Solid&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

/* ------------ CONTAINERS  */
:root {
  --black: rgb(48, 44, 41);
  --orange: #df7861;
  --bg: #fff;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: var(--bg);
  line-height: 1.3;
  color: var(--black);
}

.main-container {
  margin: 0 4%;
  padding: 80px 0 80px 0;
}

html {
  scroll-behavior: smooth;
}

/* ------------------- TYPE */

h1 {
  font-family: 'Dela Gothic One', sans-serif;
  font-size: 80px;
  text-transform: uppercase;
  margin-bottom: 72px;
}

#h1-intro {
  font-family: 'Inter', sans-serif;
  text-transform: none;
}

h3 {
  font-family: 'Dela Gothic One', sans-serif;
  font-size: 2.625rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-family: 'Dela Gothic One', sans-serif;
  font-size: 2rem;
  margin-bottom: 1.25rem;
}

h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h6, h2 {
  font-family: 'Dela Gothic One', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p, #h1-intro {
  margin: 0;
  line-height: 160%;
  font-size: 1.5rem;
}

.weight-300 {
  font-weight: 400;
}
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-700 {
  font-weight: 700;
}
.weight-900 {
  font-weight: 900;
}

.text-grey {
  color: #6D6D6F;
}

.text-white {
  color: white;
}


.londrina {
  font-family: 'Londrina Solid', sans-serif;
}

.courier-prime {
  font-family: 'Courier Prime', monospace;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.rubik {
  font-family: "Rubik", sans-serif;
}
.rocksalt {
  font-family: "Rock Salt", cursive;
}
.dmsans {
  font-family: "DM Sans", sans-serif;
}

.font-2rem {
  font-size: 2rem !important;
}
.font-1-5rem {
  font-size: 1.5rem !important;
}
.font-1-25rem {
  font-size: 1.25rem !important;
}
.font-1rem {
  font-size: 1rem !important;
}

/* ------------------------ LISTS */

ul {
  margin: 0;
  margin-right: 70px;
  margin-left: 27px;
  padding: 0;
  line-height: 1;
}

li {
  font-size: 30px;
}
li span {
  font-size: 20px;
}

.list-bullet {
  vertical-align: middle;
}

li::marker {
  color: #6D6D6F;
}

/* --------------- LINKS */
a:hover {
  text-decoration: none;
}

.tab-link:focus-visible {
  outline: 4px solid var(--black);
}
/* -----------------LOGO */

#logo-personal {
  display: flex;
  justify-content: center;
}

#logo-personal p {
  font-style: italic;
  font-weight: 300;
  text-align: center;
  width: 80%;
}



/* ---------------- RESPONSIVENESS */



@media screen and (max-width: 950px) {
  .main-container {
    padding-top: 60px;
  }
  h1 {
    font-size: 56px;
  }
  p, #h1-intro {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 750px) {
  .main-container {
    padding-top: 60px;
  }
  h1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 700px) {
  h6, h2 {
      font-size: 1.25rem;
    }
}

@media screen and (max-width: 600px) {
  .main-container {
    padding-top: 60px;
  }
  h1 {
    font-size: 36px;
  }
}

@media screen and (max-width: 500px) {
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  p,#h1-intro {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 450px) {
  .main-container {
    padding-top: 60px;
  }
  h1 {
    font-size: 24px;
  }
  h3 {
    font-size: 1.5rem;
  }
}
